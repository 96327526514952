<template>
  <VDialog
    v-model="visibility"
    max-width="600"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    persistent
  >
    <VCard class="auth-card">
      <VCardTitle class="pa-0 mb-4">
        <p :class="$vuetify.breakpoint.xs ? 'text-h6' : 'text-h4'" class="font-weight-bold mb-2">
          {{ $t("auth.reset_pass_title") }}
        </p>
        <p
          style="opacity: 0.75; word-wrap: break-word;"
          class="text-body-2 py-0 mb-0"
          :style="!$vuetify.theme.dark ? 'color: #4B5262' : 'color: #fff'"
        >
          {{ $t("auth.reset_pass_desc") }}
        </p>
      </VCardTitle>
      <VCardText class="pb-0 px-0">
        <VContainer>
          <VRow>
            <VCol cols="12" class="px-0 mb-6">
              <VTextField
                outlined
                :label="$t('form.email')"
                v-model="user.email"
                dense
                height="48px"
                class="auth-input"
                hide-details="auto"
                :error-messages="emailErrors"
                color="success"
                ref="login_email"
              /> </VCol
          ></VRow> </VContainer
      ></VCardText>
      <VRow>
        <VCol col="12" class="px-3">
          <VBtn
            color="#4FAE3B"
            class="text-white text-transform-none"
            block
            large
            depressed
            @click="onSendEmail"
          >
            {{ $t("btn.confirm") }}
          </VBtn>
        </VCol>
      </VRow>
      <VRow @click="closeDialog">
        <VCol cols="12" class="text-center">
          <VBtn text color="#4FAE3B" class="text-transform-none">
            {{ $t("auth.back_btn") }}
          </VBtn>
        </VCol>
      </VRow>
    </VCard>
  </VDialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import notifications from "../../mixins/notifications";
import langMixin from "../../mixins/language";
import requestService from "../../services/request/auth/authService";
import emailValidation from "../../validators/email";

export default {
  mixins: [validationMixin, notifications, langMixin],
  data: () => ({
    user: {
      email: ""
    }
  }),
  props: {
    visible: {
      required: true,
      type: Boolean
    }
  },
  validations: {
    user: {
      email: { required, emailValidation }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.$v.$reset();
      this.user = {};
    },
    async onSendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("email", this.user.email);
          formData.append("url_landing", `https://${window.location.host}/password/new/`);
          await requestService.passwordReset(formData);
          this.setSuccessNotification(this.$t("auth.change_pass_sended"));
          this.loading = false;
          this.$emit("close");
          this.$v.$reset();
          this.user = {};
        } catch (e) {
          this.loading = false;
          let error = e;
          if (e.statusCode == 400) {
            error = this.$t(`auth.${e.error.description.split(":")[0]}`);
          }
          this.setErrorNotification(error);
        }
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required && errors.push(this.$t("form.errors.EmailRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.emailValidation && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    }
  }
};
</script>

<style lang="scss">
.auth-input {
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        .v-label {
          margin-top: 4px;
        }
      }
    }
  }
}
.auth-card {
  max-width: 600px;
  width: 100%;
  padding: 35px 40px;
  border-radius: 10px;
  box-shadow: 0px 48px 100px 0px rgba(17, 12, 46, 0.15);
}
</style>
