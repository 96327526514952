<template>
  <AuthLayout>
    <v-card class="auth-card">
      <VFadeTransition mode="in-out">
        <VCol class="py-0 mb-8">
          <p
            :class="['text-center', $vuetify.breakpoint.xs ? 'text-h6' : 'text-h4']"
            class="font-weight-bold mb-4"
          >
            {{ $t("auth.titleLogin") }}
          </p>
          <div class="d-flex align-center justify-center">
            <span class="subheader-tag"> {{ $t("landing.title") }}</span>
          </div>
        </VCol>
      </VFadeTransition>
      <VSlideXTransition>
        <VAlert type="success" v-if="success" outlined>
          {{ $t("auth.needToActivateAccount") }}
          <b>{{ enteredEmail }}</b>
          <template slot="prepend">
            <activation-info />
          </template>
        </VAlert>
      </VSlideXTransition>
      <VForm @submit.prevent="onLogin">
        <VRow no-gutters>
          <VCol cols="12" class="py-0 mb-5">
            <VTextField
              @keypress="validationForEmail($event)"
              @paste="validationForEmail($event)"
              :maxLength="50"
              :error-messages="emailErrors"
              :label="$t('auth.email')"
              color="success"
              dense
              height="48px"
              class="auth-input"
              outlined
              hide-details="auto"
              ref="login_email"
              v-model.trim="user.email"
            />
          </VCol>
          <VCol cols="12" class="py-0 mb-5">
            <VTextField
              @keypress="validationForPassword($event)"
              @paste="validationForPassword($event)"
              :maxLength="40"
              :error-messages="passwordErrors"
              :label="$t('auth.password')"
              :type="passwordVisible ? 'text' : 'password'"
              autocomplete="new-password"
              color="success"
              dense
              @click:append="passwordVisible = !passwordVisible"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              hide-details="auto"
              height="48px"
              class="auth-input"
              outlined
              ref="login_password"
              v-model.trim="user.password"
            />
          </VCol>
          <VCol cols="12" class="d-flex align-center justify-end py-0 mb-4">
            <VBtn @click="showResetDialog" color="#4FAE3B" text class="text-transform-none">
              {{ $t("auth.btnForgot") }}
            </VBtn>
          </VCol>
          <VCol class="text-center py-0 mb-6" cols="12">
            <VBtn
              :loading="loading"
              block
              depressed
              large
              type="submit"
              color="#4FAE3B"
              class="text-white text-transform-none"
              style="font-size: 16px; font-weight: 500;"
            >
              {{ $t("auth.btnLogin") }}
            </VBtn>
          </VCol>
          <VCol class="text-center py-0 d-flex align-center justify-center" no-gutters>
            <p class="mb-0 text-body-2">{{ $t("auth.dont_have_account") }}</p>
            <VBtn
              color="#4FAE3B"
              text
              @click="redirectToRegister"
              class="font-weight-medium text-transform-none px-2"
            >
              {{ $t("auth.dont_have_account_register") }}
            </VBtn>
          </VCol>
        </VRow>
      </VForm>
      <ResetPasswordDialog :visible="isShowResetDialog" @close="closeResetDialog" />
    </v-card>
  </AuthLayout>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/auth/types/actions";
import langMixin from "../../mixins/language";
import user from "../../mixins/user";
import AuthLayout from "./authLayout/AuthLayout.vue";
import ResetPasswordDialog from "./ResetPasswordDialog.vue";
import notifications from "../../mixins/notifications";
import { validationForEmail, validationForPassword } from "@/mixins/helpers";
import activationInfo from "@/components/auth/ActivationInfo.vue";
import EventBus from "@/events/EventBus";
import passwordMask from "../../validators/passwordMask";

export default {
  name: "LoginComponent",
  mixins: [validationMixin, langMixin, notifications, user],
  components: {
    activationInfo,
    AuthLayout,
    ResetPasswordDialog
  },
  validations: {
    user: {
      email: { required, email },
      password: { required, passwordMask }
    }
  },
  data: () => ({
    passwordVisible: false,
    loading: false,
    user: {
      email: "",
      password: ""
    },
    enteredEmail: "",
    success: false,
    isShowResetDialog: false
  }),
  mounted() {
    EventBus.$on("success-registration", async item => {
      this.enteredEmail = item;
      this.success = true;
    });
  },
  methods: {
    validationForPassword,
    validationForEmail,
    showResetDialog() {
      this.isShowResetDialog = true;
    },
    closeResetDialog() {
      this.isShowResetDialog = false;
    },
    ...mapActions("auth", {
      logIn: actionTypes.LOG_IN,
      fetchUserData: actionTypes.FETCH_USER_DATA
    }),
    redirectToRegister() {
      this.$router.push("/register").catch(() => {});
    },
    async onLogin() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("email", this.user.email);
          formData.append("password", this.user.password);
          const currentLang = this.currentLanguage.lang == "en_EN" ? "en_US" : "uk_UA";
          formData.append("lang", currentLang);
          await this.logIn(formData);
          this.setSuccessNotification(this.$t("auth.loginSuccess"));
          this.loading = false;
          await this.fetchUserData();
          this.$router.push(this.$localize({ name: this.getMainPage }));
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e.error.description);
        }
      }
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    getMainPage() {
      if (this.permissions.crm_user) {
        return "crm";
      }
      return "cabinet";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required && errors.push(this.$t("form.errors.EmailRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.email && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.required && errors.push(this.$t("form.errors.PasswordRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.passwordMask && errors.push(this.$t("form.errors.PasswordMustBeLike"));
      return errors;
    }
  }
};
</script>

<style lang="scss"></style>
